import React from 'react';
import Button from '../Button';
import styles from './product.module.scss';

function ProductCard(props) {
  const titleStyle = props.subtitle ? {marginBottom: '4px'} : {};

  return (
    <div className={styles.card}>
      {props.image && (
        <img src={`${props.image.file.url}?h=290&w=500`} className={styles.image} alt={props.title}/>
      )}
      <div className={styles.content}>
        <h4 className={styles.title} style={titleStyle}>{props.title}</h4>

        {props.subtitle && (
          <p className={styles.subtitle}>{props.subtitle}</p>
        )}

        {props.content && (
          <div className={styles.text}
               dangerouslySetInnerHTML={{
                 __html: props.content.childMarkdownRemark.html
               }}>
          </div>
        )}

        {props.cta && (
          <div className={styles.wrapper}>
            <Button
              link={props.cta.url}
              size="large"
              isMobileFull
              istargetblank={props.cta.istargetblank}
              gtmEventName={props.cta.gtmEventName}
              gtmEventType={props.cta.gtmEventType}
            >
              {props.cta.label}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}

export default ProductCard;
