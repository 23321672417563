import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import styles from './media.module.scss';

function MediaCard(props) {
  let Tag = props.link ? Link : 'div';

  return (
    <div className={styles.card}>
      <Tag to={props.link}>
        {props.image && (
          <img className={styles.image} src={props.image.file.url} alt={props.name}/>
        )}
        {props.name && <h4 className={styles.name}>{props.name}</h4>}
      </Tag>
    </div>
  );
}

MediaCard.propTypes = {
  image: PropTypes.object,
  link: PropTypes.string,
  name: PropTypes.string
};

MediaCard.defaultProps = {
  image: {file: {url: ''}}
};

export default MediaCard;
