import React from "react";
import PropTypes from "prop-types";
import classNames from 'classnames/bind';
import { Col } from 'react-grid-system';

import Button from '../Button';
import Section from "../Section";

import styles from './textSection.module.scss';
let cx = classNames.bind(styles);

function TextSection(props) {
  const wrapperClassName = cx({
    wrapper: true,
    wrapperCenter: props.textAlignment === 'center'
  });

  const gridSize = props.textAlignment === 'center' ? 8 : 12;
  const gridOffset = props.textAlignment === 'center' ? {md: 2} : {};

  const variant = !!props.variant ? props.variant : 'regular';

  return (
    <Section key={props.id} hasBorder={props.hasBorder}>
      {variant === 'regular' ?
        <Col md={gridSize} offset={gridOffset}>
          <div className={wrapperClassName}>
            {props.sectionTitle && (
              <h2>{props.sectionTitle}</h2>
            )}

            {props.components && props.components.map(component => (
              <div
                key={component.id}
                className={styles.content}
                dangerouslySetInnerHTML={{
                  __html: component.content.childMarkdownRemark.html
                }}
              />
            ))}

            {props.cta &&  (
              <Button
                link={props.cta.url}
                modifierclass={styles.btn}
                istargetblank={props.cta.istargetblank}
                gtmEventName={props.cta.gtmEventName}
                gtmEventType={props.cta.gtmEventType}
                isMobileFull
              >
                {props.cta.label}
              </Button>
            )}
          </div>
        </Col>
      :
        <React.Fragment>
          <Col md={4}>
            {props.sectionTitle && (
              <h2 className={styles.headerRight}>{props.sectionTitle}</h2>
            )}
          </Col>
          <Col md={8}>
            {props.components && props.components.map(component => (
              <div
                key={component.id}
                className={styles.content}
                dangerouslySetInnerHTML={{
                  __html: component.content.childMarkdownRemark.html
                }}
              />
            ))}

            {props.cta &&  (
              <Button
                link={props.cta.url}
                modifierclass={styles.btn}
                istargetblank={props.cta.istargetblank}
                gtmEventName={props.cta.gtmEventName}
                gtmEventType={props.cta.gtmEventType}
                isMobileFull
              >
                {props.cta.label}
              </Button>
            )}
          </Col>
        </React.Fragment>
      }

    </Section>
  )
};


TextSection.defaultProps = {
  textAlignment: 'left',
  variant: 'regular'
}

TextSection.propTypes = {
  components: PropTypes.array,
  cta: PropTypes.object,
  hasBorder: PropTypes.bool,
  textAlignment: PropTypes.string,
  variant: PropTypes.string
};;

export default TextSection;
