import React from 'react';
import PropTypes from 'prop-types';
import styles from './video.module.scss';

/*
 *  Usage: <Video src='ace youtube video' ratio='4:3' />
 */
const Video = (props) => {
  if (props.videoSrc) {
    const paddingBottom = ratioToPercent(props.ratio);

    return (
      <div className={styles.wrapper} style={{paddingBottom: paddingBottom}}>
        <iframe className={styles.iframe} frameBorder={0} title={props.title} src={props.videoSrc} allowFullScreen controls/>
      </div>
    )
  }

  return null;
};

Video.propTypes = {
  posterImage: PropTypes.object,
  videoSrc: PropTypes.string,
  ratio: (props, propName, componentName) => {
    if (!/\d+:\d+/.test(props[propName])) {
      return new Error(
        'Invalid ratio supplied to ResponsiveEmbed. Expected a string like "16:9" or any 2 numbers separated by a colon'
      )
    }
  }
};

export default Video;

/*
 *  Turn `16:9` into `9 / 16` into `56.25%`
 *  Turn `4:3` into `3 / 4` into `75%`
 */
function ratioToPercent(ratio) {
  const [w, h] = ratio.split(':').map((num) => Number(num));
  return `${(h / w) * 100}%`;
}
