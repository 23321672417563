import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'react-grid-system';
import classNames from 'classnames/bind';

import Video from '../Video';
import Button from '../Button';
import styles from './mediaText.module.scss';

let cx = classNames.bind(styles);

function MediaText(props) {
  const pushPull = props.orientation === 'right' ? {md: 6} : {};
  const size = props.size === 'small' ? 3 : 6;

  const imageClassName = cx({
    image: true,
    imageSmall: props.size === 'small'
  });

  if (props.orientation === 'down') {
    return (
      <React.Fragment>
        <Col sm={12} md={6} offset={{md: 3}} className={styles.wrapper}>
          {props.title && (
            <h2>{props.title}</h2>
          )}

          {props.content && (
            <div dangerouslySetInnerHTML={{ __html: props.content.childMarkdownRemark.html}} className={styles.text}></div>
          )}
        </Col>
        <Col sm={12} md={8} offset={{md: 2}} className={styles.wrapper}>
          {props.image && (
            <img className={imageClassName} src={props.image.file.url} alt=""/>
          )}

          {props.video && (
            <Video ratio={props.video.aspectRatio} videoSrc={props.video.videoUrl}/>
          )}

          {props.cta && (
            <Button
              link={props.cta.url}
              modifierclass={styles.btn}
              isMobileFull
              istargetblank={props.cta.istargetblank}
              gtmEventName={props.cta.gtmEventName}
              gtmEventType={props.cta.gtmEventType}
            >
              {props.cta.label}
            </Button>
          )}
        </Col>
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <Col md={size} push={pushPull}>
        <div className={styles.mediaContainer}>
          {props.image && (
            <img className={imageClassName} src={props.image.file.url} alt=""/>
          )}
          {props.video && (
            <Video ratio={props.video.aspectRatio} videoSrc={props.video.videoUrl}/>
          )}
        </div>
      </Col>
      <Col md={12- size} pull={pushPull}>
        <div className={styles.content}>
          {props.title && (
            <h2>{props.title}</h2>
          )}

          {props.content && (
            <div dangerouslySetInnerHTML={{ __html: props.content.childMarkdownRemark.html}} className={styles.text}></div>
          )}

          {props.cta && (
            <Button
              link={props.cta.url}
              isMobileFull
              istargetblank={props.cta.istargetblank}
              gtmEventName={props.cta.gtmEventName}
              gtmEventType={props.cta.gtmEventType}
            >
              {props.cta.label}
            </Button>
          )}
        </div>
      </Col>
    </React.Fragment>
  )
}

MediaText.propTypes = {
  cta: PropTypes.object,
  image: PropTypes.object,
  orientation: PropTypes.string,
  size: PropTypes.string,
  title: PropTypes.string,
  content: PropTypes.object,
};

MediaText.defaultProps = {
  cta: null,
  orientation: 'left'
};

export default MediaText;
