import React from 'react';
import PropTypes from 'prop-types';

import {Link} from 'gatsby';
import styles from './profile.module.scss';


function ProfileCard(props) {
  const Tag = !props.disableLink && props.link ? Link : 'div';

  return (
    <div className={styles.card}>
      {props.image && (
        <div className={styles.imgContainer}>
          <Tag to={'/' + props.link}>
            <img className={styles.image} src={props.image.file.url} alt={props.name}/>
          </Tag>
        </div>
      )}

      <div className={styles.content}>
        {props.name && (
          <Tag to={'/' + props.link}>
            <h4 className={styles.name}>{props.name}</h4>
          </Tag>
        )}

        {props.jobTitle && (
          <p className={styles.title}>{props.jobTitle}</p>
        )}

        {props.company && (
          <p className={styles.company}>{props.company}</p>
        )}
      </div>

      {(props.showLinkedIn && props.linkedIn) && (
        <a href={props.linkedIn} target="_blank" rel="noopener noreferrer">LinkedIn</a>
      )}
    </div>
  );
}

ProfileCard.propTypes = {
  company: PropTypes.string,
  disableLink: PropTypes.bool,
  image: PropTypes.object,
  jobTitle: PropTypes.string,
  linkedIn: PropTypes.string,
  link: PropTypes.string,
  name: PropTypes.string,
  showLinkedIn: PropTypes.bool
};

ProfileCard.defaultProps = {
  disableLink: false,
  image: '',
  link: '',
  linkedIn: '',
  name: '',
  jobTitle: '',
  showLinkedIn: false
};

export default ProfileCard;
