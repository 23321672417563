import React from "react";
import {graphql} from "gatsby";
import PropTypes from "prop-types";

import Layout from "./";
import {Col, Row} from 'react-grid-system';
import SEO from "../components/Seo";
import Section from "../components/Section";
import {ContentCard} from "../components/Card";
import MarketoForm from "../components/MarketoForm";

import { setConfiguration } from 'react-grid-system';

import "../sass/index.scss";

setConfiguration({gutterWidth: 32});

const LandingPage = (props) => {
  const data = props.data.contentfulLandingPage;
  const sections = {
    ContentfulAccordion: handleAccordion,
    ContentfulBlockquote: handleBlockquote,
    ContentfulMediaTextSection: handleMediaTextSection,
    ContentfulInfoPane: handleInfoPane,
    ContentfulMediaGridSection: handleMediaGridSection,
    ContentfulSidebarSection: handleSidebarSection,
    ContentfulTextSection: handleTextSection,
    ContentfulMarketoForm: handleMarketoFormSection,
    ContentfulTypeform: handleTypeformSection,
    ContentfulFeatureList: handleFeatureList,
  };

  // console.log(data);

  let hero = null;
  let countdown = null;

  if (data.heroSection) {
    const  Hero = require("../components/Hero").default;
    hero = (
      <Section size="small" fullBleed={true}>
        <Col>
          <Hero {...data.heroSection} />
        </Col>
      </Section>
    )
  }

  if (data.countdown) {
    const  Announcement = require("../components/Announcement").default;
    countdown = (
      <Announcement {...data.countdown}/>
    )
  }

  const seoSettings = {
    description: data.seo && data.seo.description,
    keywords: (data.seo && data.seo.keywords) || [],
    image: data.seo && data.seo.featuredImage ? data.seo.featuredImage.file.url : ''
  };

  return (
    <Layout>
      <SEO title={data.title} {...seoSettings}/>

      {hero}
      {countdown}

      {data.sections && data.sections.map(section => {
        const sectionHandler = handleSection(sections, section.__typename, null);

        if (sectionHandler) {
          return sectionHandler(section)
        }

        return null;
      })}
    </Layout>
  )
};

LandingPage.propTypes = {
  data: PropTypes.object,
};

export default LandingPage;

function handleSection (cases, key, defaultCase) {
  if (cases.hasOwnProperty(key)) {
    return cases[key]
  } else {
    return defaultCase
  }
}

function handleAccordion(section) {
  const Accordion = require("../components/Accordion").default;

  return (
    <Section key={section.id}>
      <Col xs={12}>
        {section.items && (
          <Accordion>
            {section.items.map(item => {
              let content = null;

              if (item.content.length > 1) {
                content = (
                  <Row>
                    {item.content.map(contentBlock => (
                      <Col md={12 / item.content.length} key={contentBlock.id}>
                        <div dangerouslySetInnerHTML={{__html:contentBlock.content.childMarkdownRemark.html}}></div>
                      </Col>
                    ))}
                  </Row>
                )
              } else {
                content = (
                  <div dangerouslySetInnerHTML={{__html: item.content[0].content.childMarkdownRemark.html}}></div>
                );
              }

              return (
                <Accordion.Item key={item.id} title={item.title}>
                  {content}
                </Accordion.Item>
            )})}
          </Accordion>
        )}
      </Col>
    </Section>
  )
}

function handleBlockquote(section) {
  const Quote = require("../components/Quote").default;

  return <Quote key={section.id} {...section}/>;
}

function handleMediaTextSection(section) {
  const MediaText = require("../components/MediaText").default;

  return (
    <Section key={section.id} hasBorder={section.hasBorder}>
      <Col>
        {section.components.map(component =>  {
          let rowProps = {};

          if (section.imageOrientation !== "down") {
            rowProps.align = "center"
          }

          return (
            <Row key={component.id} {...rowProps}>
              <MediaText {...component} size={section.imageSize} orientation={section.imageOrientation}/>
            </Row>
          )
        })}
      </Col>
    </Section>
  )
}

function handleInfoPane(section) {
  const InfoPane = require("../components/InfoPane").default

  return (
    <InfoPane key={section.id} items={section.items}/>
  )
}

function handleMediaGridSection(section) {
  const textAlignment = section.sectionTitleAlignment || 'left';

  return (
    <Section key={section.id} fullBleed={section.fullBleed}>
      <Col>
        {section.sectionTitle && (
          <h2 style={{textAlign: textAlignment}}>{section.sectionTitle}</h2>
        )}
        {section.sectionSubtitle && (
          <div style={{textAlign: textAlignment, marginBottom: "48px"}} dangerouslySetInnerHTML={{
            __html: section.sectionSubtitle.childMarkdownRemark.html
          }}/>
        )}
        <Row>
          {section.components && section.components.map(component => {
            let sectionContent;

            if (component.__typename === 'ContentfulSponsor') {
              const MediaCard = require("../components/Card").MediaCard;
              sectionContent = <MediaCard {...component} link={`sponsors/${component.slug}`}/>;
            }

            if (component.__typename === 'ContentfulCard') {
              let Card = ContentCard;

              if (typeof window !== 'undefined' && typeof component.cardType !== 'undefined') {
                Card = require("../components/Card")[`${component.cardType}Card`];
              }

              sectionContent = <Card {...component}/>;
            }

            if (component.__typename === 'ContentfulSpeaker') {
              const ProfileCard = require("../components/Card").ProfileCard;
              const slugify = require('slugify');
              const name = slugify(`${component.firstName} ${component.lastName}`, {lower: true});
              const slug = slugify(`${name} ${component.contentful_id}`, {lower: true, remove: /[^0-9A-Za-z\s-]/g});

              sectionContent = <ProfileCard {...component}
                name={`${component.firstName} ${component.lastName}`}
                image={component.imageSrc}
                link={`/agenda/#/speakers/${slug}`}
              />;
            }

            if (component.__typename === 'ContentfulPerson') {
              const ProfileCard = require("../components/Card").ProfileCard;
              const slugify = require('slugify');
              const name = slugify(`${component.firstName} ${component.lastName}`, {lower: true});
              const slug = `${slugify(component.personRole, {lower: true})}/${name}`;

              sectionContent = <ProfileCard {...component}
                name={`${component.firstName} ${component.lastName}`}
                image={component.image}
                showLinkedIn={true}
                link={slug}
              />;
            }

            if (component.__typename === 'ContentfulTextBlock') {
              sectionContent = (
                <div dangerouslySetInnerHTML={{
                  __html: component.content.childMarkdownRemark.html
                }}/>
              )
            }

            return (
              <Col xs={12} sm={6} md={12 / section.itemsNumber} key={component.id}>
                {sectionContent}
              </Col>
          )})}

          {section.images && section.images.map(image => (
            <Col md={12 / section.itemsNumber} sm={6} xs={6} key={image.id}>
              <img src={image.file.url} alt={image.title} style={{margin: 'auto', display: 'block'}}/>
            </Col>
          ))}
        </Row>

      </Col>
    </Section>
  )
}

function handleSidebarSection(section) {
  const  Button = require("../components/Button").default;

  return (
    <Section key={section.id} fullBleed={section.fullBleed}>
      {section.sectionTitle && (
        <Col xs={12}>
          <h2>{section.sectionTitle}</h2>
        </Col>
      )}
      <Col sm={8}>
        {section.content && section.content.map(component => (
          <div key={component.id}
               dangerouslySetInnerHTML={{
                 __html: component.content.childMarkdownRemark.html
               }}
          />
        ))}
      </Col>
      <Col sm={4}>
        {section.sidebar && section.sidebar.map(component => {
          if (component.__typename === 'ContentfulCallToActionButton') {
            return (
              <Button
                istargetblank={component.istargetblank}
                key={component.id}
                link={component.url}
                isFull
                style={{marginBottom: '12px'}}
                gtmEventName={component.gtmEventName}
                gtmEventType={component.gtmEventType}
              >
                {component.label}
              </Button>)
          }

          if (component.__typename === 'ContentfulCard') {
            let Card = ContentCard;

            if (typeof window !== 'undefined' && typeof component.cardType !== 'undefined') {
              Card = require("../components/Card")[`${component.cardType}Card`];
            }

            return <Card key={component.id} {...component}/>;
          }
          return null;
        })}
      </Col>
    </Section>
  )
}

function handleTextSection(section) {
  const  TextSection = require("../components/TextSection").default;

  return (
    <TextSection key={section.id} {...section} />
  )
}

function handleMarketoFormSection(section) {
  if (section.isNewsletter) {
    return (
      <Section key={section.id}>
        {section.title && (
          <Col xs={12}>
            <h2 className="text-center-left">{section.title}</h2>
          </Col>
        )}
        <Col xs={12} md={6} push={{ md: 3 }}>
          <div className="newsletter">
            <MarketoForm {...section} />
          </div>
        </Col>
      </Section>
    )
  }

  return (
    <Section key={section.id}>
      {section.title && (
        <Col xs={12}>
          <h2 className="text-center-left">{section.title}</h2>
        </Col>
      )}
      <Col xs={12} md={6} push={{ md: 3 }}>
        <MarketoForm {...section} />
      </Col>
    </Section>
  )
}

function handleTypeformSection(section) {
  const Typeform = require("../components/Typeform").default;

  return (
    <Section key={section.id}>
      <Col xs={12}>
        <Typeform {...section}/>
      </Col>
    </Section>
  );
}

function handleFeatureList(section) {
  const FeaturedList = require("../components/FeaturedList").default;

  return (
    <Section key={section.id}>
      <Col xs={12} md={8} offset={{md: 2}}>
        <FeaturedList {...section}/>
      </Col>
    </Section>
  )
}


export const landingPageQuery = graphql`
    query($slug: String!) {
        contentfulLandingPage(slug: {eq: $slug}) {
            id,
            title,
            heroSection {
                ... on Node {
                    ... on ContentfulHero {
                        id,
                        image {
                            file {
                                url
                            }
                        },
                        title,
                        subtitle,
                        cta {
                            label,
                            url,
                            istargetblank,
                            gtmEventName,
                            gtmEventType
                        },
                        videoUrl
                    }
                }
            },
            countdown {
                id,
                title,
                dateAndTime
            },
            sections {
                __typename,
                ... on Node {
                    ... on ContentfulAccordion {
                        id
                        items {
                            id
                            title,
                            content {
                                ... on Node {
                                    __typename
                                    ... on ContentfulTextBlock {
                                        id,
                                        content {
                                            childMarkdownRemark {
                                                html
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    ... on ContentfulSidebarSection {
                        id,
                        fullBleed,
                        sectionTitle,
                        content {
                            id,
                            content {
                                childMarkdownRemark {
                                    html
                                }
                            }
                        },
                        sidebar {
                            ... on Node {
                                __typename,
                                ... on ContentfulCallToActionButton {
                                    id,
                                    label,
                                    url,
                                    istargetblank,
                                    gtmEventName,
                                    gtmEventType
                                }

                                ... on ContentfulCard {
                                    id,
                                    title,
                                    subtitle,
                                    cardType,
                                    content {
                                        childMarkdownRemark {
                                            html
                                        }
                                    },
                                    image {
                                        file {
                                            url
                                        }
                                    },
                                    cta {
                                        url,
                                        label,
                                        istargetblank,
                                        gtmEventName,
                                        gtmEventType
                                    },
                                    link
                                }
                            }
                        }
                    }

                    ... on ContentfulTextSection{
                        id,
                        sectionTitle,
                        hasBorder,
                        textAlignment,
                        variant,
                        components {
                            __typename
                            ... on ContentfulTextBlock {
                                id,
                                content {
                                    childMarkdownRemark{
                                        html
                                    }
                                }
                            }
                        },
                        cta {
                            url,
                            label,
                            istargetblank,
                            gtmEventName,
                            gtmEventType
                        }
                    }

                    ... on ContentfulMediaTextSection{
                        id,
                        fullBleed,
                        hasBorder,
                        imageSize,
                        imageOrientation,
                        components {
                            id,
                            title,
                            content {
                                childMarkdownRemark {
                                    html
                                }
                            },
                            image {
                                file {
                                    url
                                }
                            },
                            video {
                                videoUrl,
                                aspectRatio
                            },
                            cta {
                                id,
                                url,
                                label,
                                istargetblank,
                                gtmEventName,
                                gtmEventType
                            }
                        }
                    }

                    ... on ContentfulMediaGridSection {
                        id,
                        fullBleed,
                        sectionTitle,
                        sectionTitleAlignment,
                        sectionSubtitle {
                            childMarkdownRemark {
                                html
                            }
                        },
                        itemsNumber,
                        components {
                            ... on Node {
                                __typename,
                                ... on ContentfulSponsor {
                                    id,
                                    name,
                                    image {
                                        file {
                                            url
                                        }
                                    },
                                    slug
                                }

                                ... on ContentfulSpeaker {
                                    id,
                                    firstName,
                                    lastName,
                                    contentful_id,
                                    imageSrc {
                                        file {
                                            url
                                        }
                                    },
                                    jobTitle,
                                    company
                                }

                                ... on ContentfulPerson {
                                    id,
                                    firstName,
                                    lastName,
                                    personRole,
                                    image {
                                        file {
                                            url
                                        }
                                    },
                                    jobTitle,
                                    company,
                                    linkedIn,
                                    disableLink
                                }

                                ... on ContentfulCard {
                                    id,
                                    title,
                                    subtitle,
                                    cardType,
                                    content {
                                        childMarkdownRemark {
                                            html
                                        }
                                    },
                                    image {
                                        file {
                                            url
                                        }
                                    },
                                    cta {
                                        url,
                                        label,
                                        istargetblank,
                                        gtmEventName,
                                        gtmEventType
                                    },
                                    link
                                }

                                ... on ContentfulTextBlock {
                                    id,
                                    content {
                                        childMarkdownRemark{
                                            html
                                        }
                                    }
                                }
                            }
                        },
                        images {
                            id,
                            title,
                            file {
                                url
                            }
                        }
                    }

                    ... on ContentfulInfoPane {
                        id,
                        items {
                            id,
                            title,
                            subtitle,
                            link
                        }
                    }

                    ... on ContentfulMarketoForm {
                      id,
                      title,
                      isNewsletter,
                      formId,
                      gtmEventName
                    }

                    ... on ContentfulTypeform {
                      id,
                      formIdentifier,
                      height
                    }

                    ... on ContentfulBlockquote {
                        id,
                        text,
                        author,
                        company,
                        position
                    }


                    ... on ContentfulFeatureList {
                        id,
                        title,
                        hasIcon,
                        featureListItems {
                            id,
                            content
                        }
                    }
                }
            },
            seo {
                description,
                featuredImage {
                    file {
                        url
                    }
                },
                keywords
            }
        }
    }
`;
