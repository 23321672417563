import React from 'react';
import { Link } from 'gatsby';

import styles from './fullImage.module.scss';

function FullImageCard(props) {

  const inlineCardStyling = {
    backgroundImage: `url(${props.image.file.url})`
  };

  let Tag = props.cta.url ? Link : 'div';

  return (
    <React.Fragment>
      {props.cta && (
        <Tag
          to={props.cta.url}
        >
          <div className={styles.card}>
            {props.image && (
              <div className={styles.image} style={inlineCardStyling}></div>
            )}
            <div className={styles.content}>
              {props.title && (
                <React.Fragment>
                  <h3 className={styles.title}>{props.title}</h3>
                  {props.subtitle && (
                    <p className={styles.subtitle}>{props.subtitle}</p>
                  )}
                  <span className={`${styles.icon} fas fa-angle-right`}></span>
                </React.Fragment>
              )}
            </div>
          </div>
        </Tag>
      )}
    </React.Fragment>
  );
}

export default FullImageCard;
