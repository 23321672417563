import React from 'react';
import {getLinkTag} from '../../utils';

import styles from './content.module.scss';

function ContentCard(props) {
  let Tag = 'div';
  let tagProps = {};

  if (props.link) {
    const linkInformation = getLinkTag(props.link);
    Tag = linkInformation.tag;
    tagProps = linkInformation.tagProps
  }
  return (
    <Tag {...tagProps}>
      <div className={styles.card}>
        {props.image && (
          <img className={styles.image} src={props.image.file.url} alt={props.title}/>
        )}
        {props.title && (
          <h3 className={styles.title}>{props.title}</h3>
        )}
        {props.subtitle && (
          <p className={styles.subtitle}>{props.subtitle}</p>
        )}
        {props.content && (
          <div className={styles.text}
               dangerouslySetInnerHTML={{
                 __html: props.content.childMarkdownRemark.html
               }}>
          </div>
        )}
      </div>
    </Tag>
  );
}

export default ContentCard;
